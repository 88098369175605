/* We define the CSS for the HeroSection File*/

.hero-section-main-div {
	min-height: 660px;
	width: 100%;
	overflow-x: hidden;
	background-color: #156FFF;
	display: flex;
	align-items: center;
	justify-content: left;
	padding-left: 10%;
	position: relative;
	font-family: 'Inter', sans-serif;
	color: white;
}

.hero-section-sub-div {
	width: 50%;
}

.hero-background {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	overflow: hidden;
}

.hero-content {
	text-align: justify;
	font-family: 'Inter', sans-serif;
	height: 100%;
}

.hero-content h1 {
	width: 100%;
	margin-top: -1%;
	font-size: xxx-large;
	color: white;
}

.hero-content h4 {
	margin-top: -2%;
	font-size: large;
	width: 95%;
	color: white;
	margin-left: 3%;
}

.hero-circle {
	background-color: #FFFFFF80;
	border-radius: 50%;
	width: 500px;
	height: 500px;
	transform: translateX(-10%);
	position: relative;
	z-index: 1;
}

.hero-image {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: auto;
	height: 100%;
	object-fit: cover;
	z-index: 2;
}

.hero-button-position {
	display: flex;
	justify-content: center;
}

/* We define the CSS for the HowLinkClassWork File*/

.howLCworks-section {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	height: max-content;
	font-family: 'Inter', sans-serif;
}

.howLCworks-title {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.howLCworks-title h1 {
	font-size: xxx-large;
	color: #156FFF;
	font-weight: bolder;
}

.howLCworks-div {
	margin-top: 1%;
	margin-bottom: 1%;
	display: flex;
	width: 80%;
	justify-content: space-around;
	align-items: center;
}

.howLCworks-div img {
	width: 50%;
	aspect-ratio: 1.5 / 1;
}

.text-div {
	width: 40%;

}

.text-div h1 {
	font-weight: bold;
	color: #156FFF;
	font-size: xx-large;
}

.text-div h3 {
	margin-left: 7%;
	margin-top: -1%;
	text-align: justify;
	font-size: large;
	color: black;
}

/* We define the CSS for the AboutUs File*/

.AboutUsMainDiv {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: 'Inter', sans-serif;
}

.AboutUsTitleDiv {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #156FFF;
	justify-content: center;
	align-items: center;
}

.AboutUsTitleDiv h1 {
	color: white;
	text-align: center;
	font-size: xx-large;
	font-weight: bolder;
	margin-top: 5%;
	width: 80%;
}

.AboutUsTitleDiv h4 {
	margin-top: -0.5%;
	color: white;
	text-align: center;
	font-size: 20px;
	font-weight: 100;
	width: 45%;
	margin-bottom: 5%;
}

.TeamMemberMapDiv {
	background-color: white;
	margin-top: 5%;
	margin-bottom: 5%;
	width: 80%;
	display: flex;
	justify-content: space-around;
}


.IndividualTeamMemberMainDiv {
	width: 30%;
	background-color: #e0ebf7;
	border-radius: 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.IndividualTeamMemberMainDiv img {
	width: 100%;
	border-top-left-radius: 45px;
	border-top-right-radius: 45px;
}

.IndividualTeamMemberMainDiv h1 {
	color: #0045AA;
	font-weight: bolder;
	margin-top: 5%;
	margin-bottom: 3%;
	width: 90%;
	text-align: center;
	font-size: 28px;
}

.IndividualTeamMemberMainDiv h2 {
	color: #0045AA;
	font-weight: bolder;
	margin-top: 0%;
	margin-bottom: 0%;
	width: 90%;
	text-align: center;
	font-size: x-large;
}


.IndividualTeamMemberMainDiv h4 {
	font-size: medium;
	width: 90%;
	text-align: center;
	overflow-y: hidden;
	color: #0045AA;
	font-weight: lighter;
}


/* We define the CSS for the OurAwards File*/

.OurAwardsAboutUsMainDiv {
	height: auto;
	margin-top: 1%;
	margin-bottom: 1%;
	background: linear-gradient(180deg, #00E0FF 0%, #156FFF 100%);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Inter', sans-serif;
}

.OurAwardsAboutUsMainDiv h1 {
	width: 90%;
	color: white;
	font-size: 40px;
	font-weight: 750;
	text-align: center;
}

.OurAwardsAboutUsMainDiv img {
	width: 55%;
	margin-top: 2%;
	margin-bottom: 2%;
	aspect-ratio: 10/1;
}

.OurAwardsHomeMainDiv {
	margin-top: 1%;
	margin-bottom: 1%;
	height: auto;
	background: white;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Inter', sans-serif;
}

.OurAwardsHomeMainDiv h1 {
	width: 90%;
	color: #0045aa;
	font-size: 40px;
	font-weight: 750;
	text-align: center;
}

.OurAwardsHomeMainDiv img {
	width: 55%;
	margin-top: 2%;
	margin-bottom: 2%;
	aspect-ratio: 10/1;
}

/* We define the CSS for the OurBelieves File*/

.OurBelievesMainDiv {
	font-family: 'Inter', sans-serif;
	width: 100%;
	min-height: 500px;
	height: max-content;
	display: flex;
	flex-direction: column;
	background-color: white;
	align-items: center;
	justify-content: center;
}

.OurBelieveTitleDiv {
	margin-top: 2%;
	margin-bottom: 1%;
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.OurBelieveTitleDiv h1 {
	text-align: center;
	font-size: xxx-large;
	font-weight: bolder;
	color: #156FFF;
}

.OurBelievesMap {
	width: 80%;
	max-width: 1800px;
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 5%;
}

.SingularBelieveDiv {
	font-family: 'Inter', sans-serif;
	height: 300px;
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.SingularBelieveDiv h2 {
	height: 20%;
	width: 100%;
	color: #156FFF;
	text-align: center;
	font-weight: bolder;
	font-size: x-large;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
}

.SingularBelieveDiv h4 {
	height: 32%;
	width: 90%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
	color: black;
	font-size: 18px;
	font-weight: lighter;
}

.SingularBelieveDiv img {
	height: 30%;
	width: 30%;
	aspect-ratio: 1/1;
	margin: 0;
	padding: 0;
}

/* We define the CSS for the Benefits File*/

.benefits-section {
	width: 100%;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: 'Inter', sans-serif;
	color: white;
	gap: 40px;
	font-family: 'Lexend Deca', sans-serif;
}

.benefits-section-main-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 80%;
	max-width: 1800px;
}

.benefits-section-main-div h1 {
	margin-top: 5%;
	text-align: center;
	font-size: xxx-large;
	font-weight: bolder;
	color: #156FFF;
	width: 100%;
}


.benefits-section-main-div button {
	min-width: 175px;
	width: 14%;
	margin-top: 2%;
	margin-bottom: 5%;
	min-height: 6vh;
	font-family: 'Inter', sans-serif;
	font-size: large;
	font-weight: bolder;
	border: 2px solid white;
	color: white;
	background-color: #156FFF;
	border-radius: 25px;
	cursor: pointer;
}

.benefits-section-main-div button:hover {
	background-color: white;
	color: #156FFF;
	border: solid 3px #156FFF;
}

.cards-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: auto;
	justify-content: space-evenly;
	align-items: center;
}

/* We define the CSS for the HowWorksHome File*/

.HowWorksHomeMainDiv {
	width: 100%;
	height: max-content;
	background-color: #156FFF;
	display: flex;
	justify-content: center;

}

.HowWorksHomeSubDiv {
	width: 80%;
	display: flex;
	justify-content: space-evenly;
	font-family: 'Lexend Deca', sans-serif;
}

.HowWorksHomeMainDiv img {
	width: 25%;
	border-radius: 50px;
	max-width: 290px;
	margin-top: 5%;
	margin-bottom: 5%;
}

.HowWorksHomeTextDiv {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	color: white;
	margin-top: 5%;
	margin-bottom: 5%;
}

.HowWorksHomeTextDiv h1 {
	text-align: center;
	font-size: xxx-large;
	font-weight: bolder;
	color: white;
	width: 100%;
}

.HowWorksHomeTextDiv h2 {
	color: white;
	font-weight: bolder;
	margin-top: 0%;
	margin-bottom: 0%;
	width: 100%;
	text-align: left;
	font-size: xx-large;
}

.HowWorksHomeTextDiv h4 {
	width: 100%;
	text-align: justify;
	font-size: medium;
	font-weight: lighter;
}

.HowWorksHomeTextDiv button {
	min-width: 175px;
	width: 28%;
	height: 6%;
	border-radius: 25px;
	margin-bottom: 4%;
	cursor: pointer;
	font-size: large;
	font-weight: bolder;
	font-family: 'Lexend Deca', sans-serif;
	color: #156FFF;
	background-color: white;
}

.HowWorksHomeTextDiv button:hover {
	border: solid white 3px;
	color: white;
	background-color: #156FFF;
}

/* We define the CSS for the TestimoniesSection File*/

.testimonies-section {
	width: 100%;
	height: 790.66px;
	padding: 80px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	background-color: white;
	opacity: 1;
	font-family: 'Inter', sans-serif;
}

.testimonies-main-div {
	width: 80%;
	height: 100%;
}

.schools-logos {
	width: 100%;
	height: 20%;
	overflow-x: auto;
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.testimonies-main-div h1 {
	text-align: center;
	font-size: xxx-large;
	font-weight: bolder;
	color: #156FFF;
	width: 100%;
}

.carousel {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-evenly;
	gap: 20px;
	transition: transform 0.5s ease-in-out;
}

.carousel-card {
	width: 30%;
	height: 380px;
	border-radius: 20px;
	background: #e0ebf7;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.testimony-owner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25%;

}

.carousel-card h1 {
	font-size: 32px;
	font-weight: bolder;
	color: #0045AA;
}

.testimony-position {
	width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20%;
}

.carousel-card h3 {
	font-size: large;
	font-weight: bolder;
	color: #0045AA;
}

.testimony-review {
	width: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50%;
}

.carousel-card h4 {
	width: 90%;
	color: #0045AA;
}

.stars {
	height: 10%;
	font-size: 40px;
	color: #ffc700;
}

.testimonio-text {
	font-family: "Inter", sans-serif;
	font-size: 18px;
	font-style: italic;
	font-weight: 400;
	line-height: 21.78px;
	text-align: justify;
	color: #0045AA;
}

.carousel-navigation {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	gap: 10px;
}

.navigation-dot {
	width: 50px;
	height: 10px;
	background-color: gray;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.navigation-dot.active {
	background-color: #156FFF;
}

.last-page {
	justify-content: center;
}

/* We define the CSS for the HomeModal File*/

.HomeModalMainDiv {
	width: 100%;
	min-height: 70vh;
	height: max-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url('../../Assets/HomeBackground.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.HomeModalTextDiv {
	width: 80%;
	height: 40%;
}

.HomeInternalDiv {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: start;
}

.HomeInternalDiv h1 {
	margin-top: -2%;
	width: 100%;
	font-size: 44px;
	color: white;
	font-family: "Inter", sans-serif;
}

.HomeInternalDiv h4 {
	font-family: "Inter", sans-serif;
	width: 100%;
	margin-top: -1%;
	font-size: 19px;
	color: white;
	font-weight: normal;
}

.HomeInternalDiv button {
	min-width: 175px;
	width: 20%;
	margin-top: 2%;
	margin-bottom: 1%;
	min-height: 3vh;
	height: 100%;
	font-family: 'Inter', sans-serif;
	font-size: large;
	font-weight: bolder;
	border: 2px solid white;
	color: #156FFF;
	background-color: white;
	border-radius: 16px;
	cursor: pointer;
}

.HomeInternalDiv button:hover {
	background-color: #156FFF;
	color: white;
}

/* Now, we define the CSS for Phones */

@media (max-width: 768px) {

	/* HomeMainModal File */
	.HomeInternalDiv {
		width: 100%;
	}

	.HomeInternalDiv h1 {
		font-size: xx-large;
	}

	.HomeInternalDiv h4 {
		font-size: 16px;
		font-weight: normal;
	}


	/* BenefitsSection File */

	.benefits-section-main-div h1 {
		font-size: xx-large;
	}

	.benefits-section-main-div button {
		margin-bottom: 5%;
		font-weight: normal;
		padding: 0;
	}

	.cards-container {
		flex-direction: column;
	}

	.SingularBelieveDiv {
		width: 100%;
	}

	.SingularBelieveDiv h4 {
		font-size: medium;
		text-align: center;
		font-weight: normal;
	}

	/* HowWorksHome File */

	.HowWorksHomeMainDiv {
		width: 100%;
		align-items: center;
	}


	.HowWorksHomeTextDiv {
		align-items: center;
	}

	.HowWorksHomeSubDiv img {
		display: none;
	}

	.HowWorksHomeTextDiv {
		width: 80%;
	}

	.HowWorksHomeTextDiv h1 {
		width: 130%;
		font-size: xx-large;
		text-align: center;
	}

	.HowWorksHomeTextDiv h2 {
		font-size: x-large;
		text-align: center;
	}

	.HowWorksHomeTextDiv h4 {
		font-size: medium;
		text-align: center;
	}

	.HowWorksHomeTextDiv button {
		margin-top: 5%;
		margin-bottom: 5%;
	}

	/* TestimoniesSection File */

	.testimonies-section {
		height: max-content;
	}

	.testimonies-main-div {
		margin-top: -20%;
	}

	.testimonies-main-div h1 {
		font-size: xx-large;
	}

	.carousel {
		flex-direction: column;
	}

	.carousel-card {
		width: 100%;
	}

	/* HeroSection File */

	.hero-section-main-div {
		min-height: 450px !important;
	}

	.hero-background {
		display: none;
	}

	.hero-section-sub-div {
		width: 80%;
	}

	.hero-content {
		width: 100%;
	}

	.hero-content h1 {
		width: 100%;
		font-size: xx-large;
	}

	.hero-content h4 {
		width: 100%;
		font-size: medium;
	}

	/* HowLinkClassWork File */

	.howLCworks-title h1 {
		font-size: xx-large;
		text-align: center;
	}

	.howLCworks-div {
		flex-direction: column;
		width: 100%;
	}

	.howLCworks-div img {
		width: 90%;
		display: none;
	}

	.text-div {
		width: 90%;
	}

	.text-div h1 {
		font-size: x-large;
	}

	.text-div h3 {
		font-size: medium;
		font-weight: normal;
	}


	/* AboutUs File */

	.AboutUsMainDiv {
		justify-content: center;
		align-items: center;
	}

	.AboutUsTitleDiv h1 {
		font-size: xx-large;
	}

	.AboutUsTitleDiv h4 {
		font-size: medium;
		font-weight: normal;
	}

	.TeamMemberMapDiv {
		flex-direction: column;
		justify-content: center;
	}

	.IndividualTeamMemberMainDiv {
		width: 100%;
		margin-bottom: 5%;
	}

	.IndividualTeamMemberMainDiv h4 {
		font-size: small;
		font-weight: normal;
	}

	/* OurAwards File */

	.OurAwardsAboutUsMainDiv {
		min-height: min-content !important;
	}

	.OurAwardsAboutUsMainDiv h1 {
		font-size: larger;
	}

	.OurAwardsAboutUsMainDiv img {
		width: 90%;
	}

	.OurAwardsHomeMainDiv {
		min-height: min-content !important;
	}

	.OurAwardsHomeMainDiv h1 {
		font-size: larger;
	}

	.OurAwardsHomeMainDiv img {
		width: 90%;
	}

	/* OurBelieves File */

	.OurBelieveTitleDiv h1 {
		font-size: xx-large;
	}

	.OurBelievesMap {
		flex-direction: column;
	}

	.SingularBelieveDiv {
		margin-top: 8%;
	}
}