/* Default type MentalHealth */

.MentalHealthUnResolvedColorCode {
	background-color: #36C6DD !important;
}

.MentalHealthUnResolvedColorCodeTransparent {
	background-color: #36C6DD50 !important;
}

.MentalHealthUnResolvedColorCodeTransparent h4 {
	color: #36C6DD;
}

.MentalHealthSearchColorCode:hover {
	background-color: #36C6DD !important;
}

/* Default type Coexistence */

.CoexistenceUnResolvedColorCode {
	background-color: #1DBF91 !important;
}

.CoexistenceUnResolvedColorCodeTransparent {
	background-color: #1DBF9150 !important;
}

.CoexistenceUnResolvedColorCodeTransparent h4 {
	color: #1DBF91;
}

.CoexistenceSearchColorCode:hover {
	background-color: #1DBF91 !important;
}

/* Default type Tecnic */

.TecnicUnResolvedColorCode {
	background-color: #B4CC05 !important;
}

.TecnicUnResolvedColorCodeTransparent {
	background-color: #B4CC0550 !important;
}

.TecnicUnResolvedColorCodeTransparent h4 {
	color: #B4CC05 !important;
}

.TecnicSearchColorCode:hover {
	background-color: #B4CC05 !important;
}

/* Default type MissingStudent */

.MissingStudentUnResolvedColorCode {
	background-color: #F4CA45 !important;
}

.MissingStudentUnResolvedColorCodeTransparent {
	background-color: #F4CA4550 !important;
}

.MissingStudentUnResolvedColorCodeTransparent h4 {
	color: #F4CA45;
}

.MissingStudentSearchColorCode:hover {
	background-color: #F4CA45 !important;
}

/* Default type Agretion */

.AgretionUnResolvedColorCode {
	background-color: #FC6749 !important;
}

.AgretionUnResolvedColorCodeTransparent {
	background-color: #FC674950 !important;
}

.AgretionUnResolvedColorCodeTransparent h4 {
	color: #FC6749;
}

.AgretionSearchColorCode:hover {
	background-color: #FC6749 !important;
}

/* Default type Nursing */

.NursingUnResolvedColorCode {
	background-color: #E98DFC !important;
}

.NursingUnResolvedColorCodeTransparent {
	background-color: #E98DFC50 !important;
}

.NursingUnResolvedColorCodeTransparent h4 {
	color: #E98DFC !important;
}

.NursingSearchColorCode:hover {
	background-color: #E98DFC !important;
}

/* Default type Hygienic */

.HygienicUnResolvedColorCode {
	background-color: #A080F9 !important;
}

.HygienicUnResolvedColorCodeTransparent {
	background-color: #A080F950 !important;
}

.HygienicUnResolvedColorCodeTransparent h4 {
	color: #A080F9;
}

.HygienicSearchColorCode:hover {
	background-color: #A080F9 !important;
}

/* Default type General */

.GeneralUnResolvedColorCode {
	background-color: #C7E1FD !important;
}

.GeneralUnResolvedColorCodeTransparent {
	background-color: #C7E1FD50 !important;
}

.GeneralUnResolvedColorCodeTransparent h4 {
	color: #C7E1FD !important;
}

.GeneralSearchColorCode:hover {
	background-color: #C7E1FD !important;
}

/* Default type FirstSpecial */

.FirstSpecialUnResolvedColorCode {
	background-color: #D18654 !important;
}

.FirstSpecialUnResolvedColorCodeTransparent {
	background-color: #D1865450 !important;
}

.FirstSpecialUnResolvedColorCodeTransparent h4 {
	color: #D18654 !important;
}

.FirstSpecialSearchColorCode:hover {
	background-color: #D18654 !important;
}

/* Default type SecondSpecial */

.SecondSpecialUnResolvedColorCode {
	background-color: #3E9367 !important;
}

.SecondSpecialUnResolvedColorCodeTransparent {
	background-color: #3E936750 !important;
}

.SecondSpecialUnResolvedColorCodeTransparent h4 {
	color: #3E9367;
}

.SecondSpecialSearchColorCode:hover {
	background-color: #3E9367 !important;
}

/* Default type ThirdSpecial */

.ThirdSpecialUnResolvedColorCode {
	background-color: #6D6DE2 !important;
}

.ThirdSpecialUnResolvedColorCodeTransparent {
	background-color: #6D6DE250 !important;
}

.ThirdSpecialUnResolvedColorCodeTransparent h4 {
	color: #6D6DE2;
}

.ThirdSpecialSearchColorCode:hover {
	background-color: #6D6DE2 !important;
}

/* Default type FourthSpecial */

.FourthSpecialUnResolvedColorCode {
	background-color: #EA4B8C !important;
}

.FourthSpecialUnResolvedColorCodeTransparent {
	background-color: #EA4B8C50 !important;
}

.FourthSpecialUnResolvedColorCodeTransparent h4 {
	color: #EA4B8C;
}

.FourthSpecialSearchColorCode:hover {
	background-color: #EA4B8C !important;
}


.MentalHealthUnResolvedColorCode strong,
.NursingUnResolvedColorCode strong,
.AgretionUnResolvedColorCode strong,
.MissingStudentUnResolvedColorCode strong,
.HygienicUnResolvedColorCode strong,
.TecnicUnResolvedColorCode strong,
.GeneralUnResolvedColorCode strong,
.FirstSpecialUnResolvedColorCode strong,
.SecondSpecialUnResolvedColorCode strong,
.ThirdSpecialUnResolvedColorCode strong,
.FourthSpecialUnResolvedColorCode strong {
	padding: 0px;
	font-weight: bold;
}

.MentalHealthResolvedColorCodeTransparent h4,
.CoexistenceResolvedColorCodeTransparent h4,
.NursingResolvedColorCodeTransparent h4,
.AgretionResolvedColorCodeTransparent h4, 
.MissingStudentResolvedColorCodeTransparent h4,
.HygienicResolvedColorCodeTransparent h4, 
.TecnicResolvedColorCodeTransparent h4, 
.GeneralResolvedColorCodeTransparent h4, 
.FirstSpecialResolvedColorCodeTransparent h4, 
.SecondSpecialResolvedColorCodeTransparent h4, 
.ThirdSpecialResolvedColorCodeTransparent h4,
.FourthSpecialResolvedColorCodeTransparent h4 {
	color: #C7E1FD !important
} 

.MentalHealthResolvedColorCodeTransparent,
.CoexistenceResolvedColorCodeTransparent,
.NursingResolvedColorCodeTransparent,
.AgretionResolvedColorCodeTransparent, 
.MissingStudentResolvedColorCodeTransparent,
.HygienicResolvedColorCodeTransparent, 
.TecnicResolvedColorCodeTransparent, 
.GeneralResolvedColorCodeTransparent, 
.FirstSpecialResolvedColorCodeTransparent, 
.SecondSpecialResolvedColorCodeTransparent, 
.ThirdSpecialResolvedColorCodeTransparent,
.FourthSpecialResolvedColorCodeTransparent {
	background-color: #C7E1FD50 !important
} 

.MentalHealthResolvedColorCode:hover,
.CoexistenceResolvedColorCode:hover,
.NursingResolvedColorCode:hover,
.AgretionResolvedColorCode:hover, 
.MissingStudentResolvedColorCode:hover,
.HygienicResolvedColorCode:hover, 
.TecnicResolvedColorCode:hover, 
.GeneralResolvedColorCode:hover, 
.FirstSpecialResolvedColorCode:hover, 
.SecondSpecialResolvedColorCode:hover, 
.ThirdSpecialResolvedColorCode:hover,
.FourthSpecialResolvedColorCode:hover {
	background-color: #F0F7FD;
} 

/* We define all the emergency codes on Resolved */

.MentalHealthResolvedColorCode strong {
	background-color: #36C6DD;
	font-weight: bold;
}

.CoexistenceResolvedColorCode strong{
	background-color: #1DBF91;
	font-weight: bold;
}

.NursingResolvedColorCode strong {
	background-color: #E98DFC;
	font-weight: bold;
}

.AgretionResolvedColorCode strong {
	background-color: #FC6749;
	font-weight: bold;
}

.MissingStudentResolvedColorCode strong {
	background-color: #F4CA45;
	font-weight: bold;
}

.HygienicResolvedColorCode strong {
	background-color: #A080F9;
	font-weight: bold;
}

.TecnicResolvedColorCode strong {
	background-color: #B4CC05;
	font-weight: bold;
}

.GeneralResolvedColorCode strong {
	background-color: #C7E1FD;
	font-weight: bold;
}

.FirstSpecialResolvedColorCode strong {
	background-color: #D18654;
	font-weight: bold;
}

.SecondSpecialResolvedColorCode strong {
	background-color: #3E9367;
	font-weight: bold;
}

.ThirdSpecialResolvedColorCode strong {
	background-color: #6D6DE2;
	font-weight: bold;
}

.FourthSpecialResolvedColorCode strong {
	background-color: #EA4B8C;
	font-weight: bold;
}



.MentalHealthUnResolvedColorCodeCommentButton button {
	border: solid 2px #36C6DD;
	color: #36C6DD;
}

.MentalHealthUnResolvedColorCodeCommentButton button:hover {
	background-color: #36C6DD;
	border: solid 2px #36C6DD;
	color: white;
}

.CoexistenceUnResolvedColorCodeCommentButton button {
	border: solid 2px #1DBF91;
	color: #1DBF91;
}

.CoexistenceUnResolvedColorCodeCommentButton button:hover {
	background-color: #1DBF91;
	border: solid 2px #1DBF91;
	color: white;
}

.NursingUnResolvedColorCodeCommentButton button  {
	border: solid 2px #E98DFC;
	color: #E98DFC;
}

.NursingUnResolvedColorCodeCommentButton button:hover  {
	background-color: #E98DFC;
	border: solid 2px #E98DFC;
	color: white;
}


.AgretionUnResolvedColorCodeCommentButton button  {
	border: solid 2px #FC6749;
	color: #FC6749;
}

.AgretionUnResolvedColorCodeCommentButton button:hover  {
	background-color: #FC6749;
	border: solid 2px #FC6749;
	color: white;
}

.MissingStudentUnResolvedColorCodeCommentButton button  {
	border: solid 2px #F4CA45;
	color: #F4CA45;
}

.MissingStudentUnResolvedColorCodeCommentButton button:hover  {
	background-color: #F4CA45;
	border: solid 2px #F4CA45;
	color: white;
}


.HygienicUnResolvedColorCodeCommentButton button  {
	border: solid 2px #A080F9;
	color: #A080F9;
}

.HygienicUnResolvedColorCodeCommentButton button:hover {
	background-color: #A080F9;
	border: solid 2px #A080F9;
	color: white;
}

.TecnicUnResolvedColorCodeCommentButton button  {
	border: solid 2px #B4CC05;
	color: #B4CC05;
}

.TecnicUnResolvedColorCodeCommentButton button:hover  {
	background-color: #B4CC05;
	border: solid 2px #B4CC05;
	color: white;
}

.GeneralUnResolvedColorCodeCommentButton button  {
	border: solid 2px #C7E1FD;
	color: #C7E1FD;
}

.GeneralUnResolvedColorCodeCommentButton button:hover  {
	background-color: #C7E1FD;
	border: solid 2px #C7E1FD;
	color: white;
}

.FirstSpecialUnResolvedColorCodeCommentButton button  {
	border: solid 2px #D18654;
	color: #D18654;
}

.FirstSpecialUnResolvedColorCodeCommentButton button:hover  {
	background-color: #D18654;
	border: solid 2px #D18654;
	color: white;
}

.SecondSpecialUnResolvedColorCodeCommentButton button  {
	border: solid 2px #3E9367;
	color: #3E9367;
}

.SecondSpecialUnResolvedColorCodeCommentButton button:hover  {
	background-color: #3E9367;
	border: solid 2px #3E9367;
	color: white;
}

.ThirdSpecialUnResolvedColorCodeCommentButton button  {
	border: solid 2px #6D6DE2;
	color: #6D6DE2;
}

.ThirdSpecialUnResolvedColorCodeCommentButton button:hover  {
	background-color: #6D6DE2;
	border: solid 2px #6D6DE2;
	color: white;
}

.FourthSpecialUnResolvedColorCodeCommentButton button  {
	border: solid 2px #EA4B8C;
	color: #EA4B8C;
}

.FourthSpecialUnResolvedColorCodeCommentButton button:hover  {
	background-color: #EA4B8C;
	border: solid 2px #EA4B8C;
	color: white;
}


/* We need to define the css of the fastcomment options */

/* Default type MentalHealth */

.FastPositiveCommentMentalHealthUnResolvedColorCode {
	border: solid 2px #36C6DD !important;
	background-color: #36C6DD !important;
	color: black !important;
}

.FastNegativeCommentMentalHealthUnResolvedColorCode {
	border: solid 2px #36C6DD;
	background-color: transparent;
	color: #36C6DD !important;
}

/* Default type Coexistence */

.FastPositiveCommentCoexistenceUnResolvedColorCode {
	border: solid 2px #1DBF91;
	background-color: #1DBF91;
	color: black !important;
}

.FastNegativeCommentCoexistenceUnResolvedColorCode {
	border: solid 2px #1DBF91;
	background-color: transparent;
	color: #1DBF91 !important;
}

/* Default type Tecnic */

.FastPositiveCommentTecnicUnResolvedColorCode {
	border: solid 2px #B4CC05;
	background-color: #B4CC05;
	color: black !important;
}

.FastNegativeCommentTecnicUnResolvedColorCode {
	border: solid 2px #B4CC05;
	background-color: transparent;
	color: #B4CC05 !important;
}

/* Default type MissingStudent */

.FastPositiveCommentMissingStudentUnResolvedColorCode {
	border: solid 2px #F4CA45;
	background-color: #F4CA45;
	color: black !important;
}

.FastNegativeCommentMissingStudentUnResolvedColorCode {
	border: solid 2px #F4CA45;
	background-color: transparent;
	color: #F4CA45 !important;
}

/* Default type Agretion */

.FastPositiveCommentAgretionUnResolvedColorCode {
	border: solid 2px #FC6749;
	background-color: #FC6749;
	color: black !important;
}

.FastNegativeCommentAgretionUnResolvedColorCode {
	border: solid 2px #FC6749;
	background-color: transparent;
	color: #FC6749 !important;
}

/* Default type Nursing */

.FastPositiveCommentNursingUnResolvedColorCode {
	border: solid 2px #E98DFC;
	background-color: #E98DFC;
	color: black !important;
}

.FastNegativeCommentNursingUnResolvedColorCode {
	border: solid 2px #E98DFC;
	background-color: transparent;
	color: #E98DFC !important;
}

/* Default type Hygienic */

.FastPositiveCommentHygienicUnResolvedColorCode {
	border: solid 2px #A080F9;
	background-color: #A080F9;
	color: black !important;
}

.FastNegativeCommentHygienicUnResolvedColorCode {
	border: solid 2px #A080F9;
	background-color: transparent;
	color: #A080F9 !important;
}

/* Default type General */


/* Default type FirstSpecial */

.FastPositiveCommentFirstSpecialUnResolvedColorCode {
	border: solid 2px #D18654;
	background-color: #D18654;
	color: black !important;
}

.FastNegativeCommentFirstSpecialUnResolvedColorCode {
	border: solid 2px #D18654;
	background-color: transparent;
	color: #D18654 !important;
}

/* Default type SecondSpecial */

.FastPositiveCommentSecondSpecialUnResolvedColorCode {
	border: solid 2px #3E9367;
	background-color: #3E9367;
	color: black !important;
}

.FastNegativeCommentSecondSpecialUnResolvedColorCode {
	border: solid 2px #3E9367;
	background-color: transparent;
	color: #3E9367 !important;
}

/* Default type ThirdSpecial */

.FastPositiveCommentThirdSpecialUnResolvedColorCode {
	border: solid 2px #6D6DE2;
	background-color: #6D6DE2;
	color: black !important;
}

.FastNegativeCommentThirdSpecialUnResolvedColorCode {
	border: solid 2px #6D6DE2;
	background-color: transparent;
	color: #6D6DE2 !important;
}

/* Default type FourthSpecial */

.FastPositiveCommentFourthSpecialUnResolvedColorCode {
	border: solid 2px #EA4B8C;
	background-color: #EA4B8C;
	color: black !important;
}

.FastNegativeCommentFourthSpecialUnResolvedColorCode {
	border: solid 2px #EA4B8C;
	background-color: transparent;
	color: #EA4B8C !important;
}

/* Resolved shared colors */

.FastPositiveCommentMentalHealthResolvedColorCode, .FastPositiveCommentCoexistenceResolvedColorCode,
.FastPositiveCommentTecnicResolvedColorCode, .FastPositiveCommentMissingStudentResolvedColorCode,
.FastPositiveCommentAgretionResolvedColorCode, .FastPositiveCommentNursingResolvedColorCode, 
.FastPositiveCommentHygienicResolvedColorCode, .FastPositiveCommentFirstSpecialResolvedColorCode,
.FastPositiveCommentSecondSpecialResolvedColorCode, .FastPositiveCommentThirdSpecialResolvedColorCode,
.FastPositiveCommentFourthSpecialResolvedColorCode {
	border: solid 2px #C7E1FD;
	background-color: #C7E1FD;
	color: black !important;
}


.FastNegativeCommentMentalHealthResolvedColorCode, .FastNegativeCommentCoexistenceResolvedColorCode,
.FastNegativeCommentTecnicResolvedColorCode, .FastNegativeCommentMissingStudentResolvedColorCode,
.FastNegativeCommentAgretionResolvedColorCode, .FastNegativeCommentNursingResolvedColorCode, 
.FastNegativeCommentHygienicResolvedColorCode, .FastNegativeCommentFirstSpecialResolvedColorCode,
.FastNegativeCommentSecondSpecialResolvedColorCode, .FastNegativeCommentThirdSpecialResolvedColorCode
.FastNegativeCommentFourthSpecialResolvedColorCode {
	border: solid 2px #C7E1FD;
	background-color: transparent;
	color: #C7E1FD !important;
}