.landing-footer {
    background: linear-gradient(180deg, #00E0FF 0%, #156FFF 100%);
    width: 100%;
    padding-bottom: -10px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo {
    height: 100px;
    padding-left: 30px;
}

.socials { 
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    padding-right: 30px;
}

.socials-logo {
    width: 40px;
    height: 40px;
}

.footer-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: end;
}

.footer-text h4{
    width: 415px;
    height: 22px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 21.78px;
    color: white;
}