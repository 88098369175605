/* We start defining css of the multiple modals files */

.AdminModalMainDiv{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdminModalMainDiv button{
    background-color: #156FFF;
    border: solid 2px #156FFF;
    border-radius: 10%;
    min-height: 40%;
    color: white;
    cursor: pointer;  
}

.AdminModalMainDiv button:hover{
    background-color: white; 
    color: #156FFF;
    border: solid 2px #156FFF;
    cursor: pointer; 
}


/* We start defining css Of the SchoolListModal file */


.SchoolListMapDiv{
    width: 100%;
    height: 89%;
    overflow-y: auto;
}

.SchoolListMapDivRow{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

/* We start defining css Of the CreateSchoolButtonComponent file */

.CreateSchoolBox{
    margin: 1%;
    width: 15%;
    border: solid 2px #156FFF;
    background-color: #156FFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CreateSchoolBox h2 {
    color: white;
    font-size: larger;
    text-align: center;
}

.CreateSchoolBox:hover {
    background-color: white;
}

.CreateSchoolBox:hover h2 {
    color: #156FFF;
}


/* We start defining css Of the ViewSingularSchoolComponent file */

.ViewSchoolDiv{
    margin: 1%;
    width: 15%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: solid 2px white;
}

.ViewSchoolDiv:hover {
    border: solid 2px #156FFF;
}

.ViewSchoolImageDiv{
    background-color: white;
    width: 100%;
    height: 60%;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ViewSchoolImageDiv img{
    width: 80%;
    height: 100%;
    cursor: pointer;
}

.ViewSchoolNameDiv{
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;
    text-align: center;
    cursor: pointer;
}

.ViewSchoolButtonDiv{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.ViewSchoolButtonDiv button{
    width: 35%;
}


.ViewEmptySchoolDiv{
    margin: 1%;
    aspect-ratio: 1 / 1;
    width: 15%;
    border-radius: 10%;
    background-color: white;
}

/* We start defining css of the EditSchoolComponent file */

.EditSchoolMainDiv{
    width: 90%;
    min-height: 61vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.EditSchoolMainDiv button {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 40%;
    padding: 10px;
    border-radius: 20px;
    border: solid 2px #009FFF;
    background-color: #009FFF;
    color: white;
    cursor: pointer;
    font-size: large;
    font-weight: bold;
}

.EditSchoolMainDiv button:hover {
    background-color: white;
    color: #009FFF;
}


.EditSchoolImageDiv{
    height: 55%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EditSchoolImageDiv button {
    width: 20% !important;
}

.EditSchoolImageDiv img {
    margin-top: 2%;
    width: 25%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
}

.EditSchoolInputDiv{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
}

.EditSchoolInputDiv label{
    color: #0045aa;
    width: 10%;
    display: flex;
    align-items: center;
}

.EditSchoolInputDiv input{
    width: 60%;
    padding: 10px;
    color: #0045aa;
    background-color: white;
    border: solid 2px #009FFF;
    border-radius: 25px;
}

.EditSchoolButtonDiv{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.EditSchoolButtonDiv button{
    width: 25%;
}


/* We start defining css of the CreateSchoolComponent file */

.CreateSchoolMainInternalDiv{
    width: 90%;
    height: 100%;
    border-radius: 20px;
    min-height: 61vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CreateSchoolMainInternalDiv button{
    margin-top: 2%;
    margin-bottom: 2%;
    width: 20%;
    padding: 10px;
    border-radius: 20px;
    border: solid 2px #009FFF;
    background-color: #009FFF;
    color: white;
    cursor: pointer;
    font-size: large;
    font-weight: bold;
}

.CreateSchoolMainInternalDiv button:hover {
    background-color: white;
    color: #009FFF;
}

.CreateSchoolImageDiv{
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CreateSchoolImageDiv img {
    margin-top: 2%;
    width: 25%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
}

.CreateSchoolImageDiv input {
    margin-top: 5%;
}

.CreateSchoolDoubleInputDiv{
    margin-top: 2%;
    margin-bottom: 2%;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.CreateSchoolInputLabel {
    width: 49%;
    display: flex;
    flex-direction: row;
}

.CreateSchoolInputLabel label{
    color: #0045aa;
    width: 20%;
    display: flex;
    align-items: center;
}

.CreateSchoolInputLabel input{
    width: 80%;
    padding: 10px;
    color: #0045aa;
    background-color: white;
    border: solid 2px #009FFF;
    border-radius: 25px;
}


.CreateSchoolButtonDiv{
    width: 60%;
    height: 11%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.CreateSchoolButtonDiv button{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 40%;
}