/* UserModalSelecter File */

.ModalSelectorButtonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 2.5%;
    cursor: pointer;
}

.ModalSelectorButtonDiv img {
    width: 15%;
    margin-left: 5%;
    aspect-ratio: 1 / 1;
}

.ModalSelectorSubDiv {
    width: 75%;
    background-color: transparent;
    font-size: medium;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: left;
}

.MainButtonDiv {
    margin-top: 5%;
    background-color: #0045AA;
    border: solid #0045AA 2px;
    color: white !important;
    border-radius: 50px;
}

.MainButtonDiv:hover {
    background-color: #F0F7FD;
    border: solid #0045AA 2px;
    border-radius: 50px;
    color: #0045AA !important;
}


/* UserHomeModal File*/

.HomeModalSuperiorDiv{
    width: 90%;
    min-height: 15vh;
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
}

.HomeModalButtonDiv{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.HomeModalSelectedType {
    width: 45%;
    max-height: 35px;
    border-radius: 15px;
    background-color: #009FFF;
    border: solid 1px #009FFF;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
}

.HomeModalUnselectedType {
    width: 45%;
    max-height: 35px;
    border-radius: 15px;
    background-color: #F0F7FD;
    border: solid 2px #009FFF;
    color: #009FFF;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: normal;
    cursor: pointer;
}

.HomeModalSuperiorInteriorDiv{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HomeModalSuperiorInteriorDiv button {
    width: 38%;
    cursor: pointer;
    max-height: 35px;
    border-radius: 15px;
    background-color: #009FFF;
    border: solid 1px #009FFF;
    color: #F0F7FD;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: bold;
}

.HomeModalSuperiorInteriorDiv button:hover {
    border-radius: 15px;
    background-color: #F0F7FD;
    border: solid 2px #009FFF;
    color: #009FFF;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
}

/* UserNoticeHistory File*/

.UserHistorySuperiorDiv{
    min-height: 8vh;
    height: 18%;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.UserHistorySuperiorDiv button {
    width: 45%;
}

.UserHistorySuperiorDivBorder {
    border-bottom: solid 2px #009FFF;
}

.UserHistoryMainButtonDiv{
    width: 55%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.UserHistorySecondaryButtonDiv{
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.FilterButton {
    cursor: pointer;
    border: solid 2px red;
    width: 30% !important;
    background-color: transparent;
    border: transparent;
    color: #009FFF;
    font-size: large;
}



.UserHistoryNoticesBox {
    border-top: solid 2px #009FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

/* ReportUsers File*/

.ReportUsersSuperiorDiv {
    width: 90%;
    min-height: 15vh;
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
}

.ReportUserSuperiorSubDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.ReportUserSelectedType {
    width: 45%;
    max-height: 35px;
    border-radius: 15px;
    background-color: #009FFF;
    border: solid 1px #009FFF;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
}

.ReportUserUnselectedType {
    width: 45%;
    max-height: 35px;
    border-radius: 15px;
    background-color: #F0F7FD;
    border: solid 2px #009FFF;
    color: #009FFF;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: normal;
    cursor: pointer;
}

.ReportUserCreateButton {
    width: 38%;
    cursor: pointer;
    max-height: 35px;
    border-radius: 15px;
    background-color: #009FFF;
    border: solid 1px #009FFF;
    color: #F0F7FD;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: bold;
}

.ReportUserCreateButton:hover {
    border-radius: 15px;
    background-color: #F0F7FD;
    border: solid 2px #009FFF;
    color: #009FFF;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
}

.CreateIndividualReportMainDiv {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CreateReportButton {
    width: 38%;
    cursor: pointer;
    max-height: 35px;
    border-radius: 15px;
    background-color: #009FFF;
    border: solid 1px #009FFF;
    color: #F0F7FD;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: bold;
    margin-bottom: 2%;
}

.CreateReportButton:hover{
    background-color: #F0F7FD;
    border: solid 2px #009FFF;
    color: #009FFF;
}

.CreateIndividualReportDataField {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.CreateIndividualReportDataField h3 {
    font-size: large;
	font-weight: bold;
	color: #0045aa;
}

.CreateIndividualReportDataField input {
    padding: 10px;
    width: calc(100% - 24px);
    border-radius: 100px;
    border: solid 2px #009FFF;
    color: #0045aa;
    font-size: small;
}

.CreateIndividualReportDateField {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.CreateIndividualReportDateField h3 {
    font-size: large;
    text-align: start;
	font-weight: bold;
	color: #0045aa;
    width: 100%;
}

.CreateIndividualReportDateMainField {
    width: 100%;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px #009FFF;
}

.CreateIndividualReportDateSubField{
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.CreateIndividualReportIndividualDate{
    width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 100px;
    align-items: center;
}
.CreateIndividualReportIndividualDate input {
    padding: 5px;
    width: 50%;
    border-radius: 100px;
    color: #0045aa;
    font-size: small;
    border: solid 2px #009FFF;
}

.CreateIndividualReportIndividualDate h4 {
    margin-left: 5%;
    color: #0045aa;
}

.CreateIndividualReportTypeField{
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.CreateIndividualReportTypeField h3 {
    font-size: large;
	font-weight: bold;
	color: #0045aa;
}

.CreateIndividualReportTypeBox {
    display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 2%;
    border: solid 2px #009FFF;
    border-radius: 200px;
    color: #009FFF;
}

.CreateIndividualReportTypeBox button{
    color: #009FFF;
}

.ReportTypeSelected {
	width: 20%;
	padding: 10px;
	margin: 1%;
	border-radius: 25px;
	cursor: pointer;
	font-weight: lighter;
    font-size: small;
	border: solid 3px #009FFF ;
	background-color: #F0F7FD;
    color: black !important;
}

.ReportTypeUnSelected {
	width: 20%;
	padding: 10px;
	margin: 1%;
	border-radius: 25px;
	cursor: pointer;
	border: solid 2px #009FFF;
    background-color: transparent;
}

.ReportGroupSelect {
    width: 100%;
    display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px;
	align-items: center;
	text-align: center;
	border-radius: 30px;
	border: solid 2px #009FFF;
	background-color: white;
	font-family: 'Inter', sans-serif;
	font-size: large;
	font-weight: lighter;
	color: #009FFF;
	box-sizing: border-box;
}