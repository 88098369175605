.ETButtonDiv {
    width: 90%;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.ETButton {
    width: 45%;
    max-height: 35px;
    border-radius: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
}

.SelectedButton {
    background-color: #009FFF;
    border: solid 2px #009FFF;
    color: white;
}

.UnSelectedButton {
    background-color: #F0F7FD;
    border: solid 2px #009FFF;
    color: #009FFF;
}

/* SchoolAdminAllGroups */

.ViewAllGroupsMainDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AllGroupsButtonsDiv {
    width: 65%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.AllGroupsButtonsDiv button{
    padding: 15px;
    margin-top: 4%;
    margin-bottom: 4%;
    border: #009FFF solid 2px;
    border-radius: 100px;
    font-size: medium;
    background-color: #009FFF;
    color: white;
    cursor: pointer;
}

.AllGroupsButtonsDiv button:hover{
    background-color: white;
    color: #009FFF;
    cursor: pointer;
}

.AllGroupsTable {
    width: 100%;
    border-collapse: collapse;
}

.AllGroupsTable thead {
    border-bottom: solid 2px #009FFF;
    color: #009FFF !important;
    font-size: large;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
}
.AllGroupsTable thead tr {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.AllGroupsTable thead td, .AllGroupsTable thead th {
    padding: 10px;
    text-align: left;
    color: #0045aa;
    display: flex;
    flex-direction: row;
}

.AllGroupsTable th,
.AllGroupsTable td {
    text-align: left;
    color: #0045aa;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AllGroupsTable tbody tr {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    display: flex;
    align-items: center;
}

.AllGroupsTable th:nth-child(1),
.AllGroupsTable td:nth-child(1),
.AllGroupsTable th:nth-child(3),
.AllGroupsTable td:nth-child(3) {
    width: 10%;
    text-align: center;
    justify-content: space-evenly;
}

.AllGroupsTable th:nth-child(2),
.AllGroupsTable td:nth-child(2) {
    width: 70%;
    justify-content: start;
}

.AllGroupsTable th:nth-child(4),
.AllGroupsTable td:nth-child(4),
.AllGroupsTable th:nth-child(5),
.AllGroupsTable td:nth-child(5) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 5%;
}

.AllGroupsTable th:nth-child(4) button,
.AllGroupsTable td:nth-child(4) button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: normal;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.AllGroupsTable td:nth-child(4) button img {
    width: 50%;
}

.ExtraButtonsGroup button img {
    width: 100% !important;
}

.AllGroupsTable th:nth-child(3) button,
.AllGroupsTable td:nth-child(3) button {
    background-color: #009FFF;
    color: white;
    border: solid #009FFF 2px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
}

.AllGroupsTable th:nth-child(3) button:hover,
.AllGroupsTable td:nth-child(3) button:hover {
    background-color: white;
    color: #009FFF;
}

.AllGroupsTable th:nth-child(5) button,
.AllGroupsTable td:nth-child(5) button {
    width: 60%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: normal;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.AllGroupsTable td:nth-child(5) button img {
    width: 100%;
}

/* SchoolAdminCreateGroupModal */

.CreateGroupTitleDiv {
    width: 50%;
}

.NameGroupInput {
    width: 85%;
    padding: 15px;
    border-radius: 30px;
    border: 2px solid #009FFF;
    position: relative;
    font-size: medium;
    margin-top: 2%;
    margin-bottom: 1%;
}

.CreateGroupUserDiv, .CreateGroupStudentDiv {
    width: 75%;
    margin-bottom: 2%;
}

.CreateGroupTitleDiv h4, .CreateGroupUserDiv h4, .CreateGroupStudentDiv h4 {
    margin-bottom: 1%;
    color: #0045aa;
}

.CreateGroupButton{
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 2%;
    border: #009FFF solid 2px;
    border-radius: 100px;
    font-size: medium;
    background-color: #009FFF;
    color: white;
    cursor: pointer;
}

.CreateGroupButton:hover{
    background-color: white;
    color: #009FFF;
    cursor: pointer;
}

/* SchoolAdminSAUModal */

.StudentsAndUserButtonDiv {
    width: 90%;
    display: flex;
    justify-content: center;
}

.StudentsAndUserDiv {
    width: 65%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.StudentsAndUserDiv button{
    padding: 15px;
    border: #009FFF solid 2px;
    border-radius: 100px;
    font-size: medium;
    background-color: #009FFF;
    color: white;
    cursor: pointer;
}

.StudentsAndUserDiv button:hover{
    background-color: white;
    color: #009FFF;
    cursor: pointer;
}

/* CreateStudent */

.CreateStudentMainDiv{
    width: 75%;
    color: #0045aa;
}

.CreateStudentTopDiv {
    margin-top: 2%;
}

.CreateStudentTopDiv input {
    padding: 12px;
    width: calc(100% - 30px);
}

.CreateStudentNameInput{
    width: calc(100% - 17px);
    padding: 5px;
    padding-left: 10px;
    border-radius: 30px;
    border: 2px solid #009FFF;
    position: relative;
    font-size: medium;
    color: #0045aa;
    margin-top: -0.5%;
}

.CreateStudentGroupDiv {
    width: 100%;
}

.CreateStudentGroupDiv h4 {
    margin-bottom: 1%;
}

.CreateStudentGroupInternalDiv{
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
}

.CreateStudentMapGroupDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.CreateStudentMapGroupDiv button{
    margin: 1%;
    border: solid 2px #009FFF;
    border-radius: 100px;
    padding: 10px;
    width: 20%;
    text-align: center;
    cursor: pointer;
    background-color: white;
    color: #0045aa;
    font-size: medium;
    font-weight: bolder;
}

.CreateStudentButtonDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.CreateStudentButtonDiv button {
    width: 30%;
    max-height: 35px;
    border-radius: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
    background-color: #009FFF;
    border: solid 2px #009FFF;
    color: white;
}

.CreateStudentButtonDiv button:hover {
    background-color: white;
    border: solid 2px #009FFF;
    color: #009FFF;
}


/* Create User */

.CreateUserMainDiv {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #0045aa;
}

.CreateUserImgDiv {
    margin-top: 2%;
    width: 50%;
    display: flex;
    justify-content: center;
}

.CreateUserImgDiv img {
    width: 30%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
}

.CreateUserTwoInternalDivs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CreateUserTwoInternalDivs input {
    width: calc(100% - 17px);
    padding: 5px;
    padding-left: 10px;
    border-radius: 30px;
    border: 2px solid #009FFF;
    font-size: small;
    color: #0045aa;
    margin-top: -0.5%;
}

.CreateUserTwoInternalSubDiv {
    width: 47%;
    display: flex;
    flex-direction: column;
}

.CreateUserGroupDiv {
    width: 100%;
}

.CreateUserGroupDiv h4 {
    margin-bottom: 1%;
}

.CreateUserGroupInternalDiv{
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
}

.CreateUserMapGroupDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.CreateUserMapGroupDiv button{
    margin: 1%;
    border: solid 2px #009FFF;
    border-radius: 100px;
    padding: 10px;
    width: 20%;
    text-align: center;
    cursor: pointer;
    background-color: white;
    color: #0045aa;
    font-size: medium;
    font-weight: bolder;
    overflow-x: hidden;
}

.CreateUserButtonDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.CreateUserButtonDiv button {
    width: 30%;
    max-height: 35px;
    border-radius: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
    background-color: #009FFF;
    border: solid 2px #009FFF;
    color: white;
    margin-bottom: 2%;
}

.CreateUserButtonDiv button:hover {
    background-color: white;
    border: solid 2px #009FFF;
    color: #009FFF;
}